// Generated by Framer (c76752e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["MGVSxjS4S", "YG_5kns_4", "FfuxrBpMv", "u4WNz6cpY"];

const serializationHash = "framer-g2vNz"

const variantClassNames = {FfuxrBpMv: "framer-v-orjkhc", MGVSxjS4S: "framer-v-641rrv", u4WNz6cpY: "framer-v-dobmtb", YG_5kns_4: "framer-v-au8yqj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.7, ease: [0, 0, 1, 1], type: "tween"}

const transition2 = {delay: 0, duration: 3, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Card Gradient Bot-left": "u4WNz6cpY", "Card Gradient Bot-right": "FfuxrBpMv", "Card Gradient Left": "MGVSxjS4S", "Card Gradient Right": "YG_5kns_4"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "MGVSxjS4S"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "MGVSxjS4S", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearx4yxc4 = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 700)
})

const onAppear1e1frkv = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 3000)
})

useOnVariantChange(baseVariant, {default: onAppearx4yxc4, YG_5kns_4: onAppear1e1frkv})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({YG_5kns_4: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-641rrv", className, classNames)} data-framer-name={"Card Gradient Left"} data-highlight layoutDependency={layoutDependency} layoutId={"MGVSxjS4S"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 33, borderBottomRightRadius: 33, borderTopLeftRadius: 33, borderTopRightRadius: 33, ...style}} {...addPropertyOverrides({FfuxrBpMv: {"data-framer-name": "Card Gradient Bot-right"}, u4WNz6cpY: {"data-framer-name": "Card Gradient Bot-left"}, YG_5kns_4: {"data-framer-name": "Card Gradient Right"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1fzct5f"} data-framer-name={"Stroke"} layoutDependency={layoutDependency} layoutId={"XKPiQP2zS"} style={{background: "radial-gradient(10% 10% at 17.599999999999998% 0%, var(--token-39139dc2-5826-48d3-90f9-4b466d460b8b, rgb(173, 208, 21)) /* {\"name\":\"Yellow 600\"} */ 0%, rgba(255, 255, 255, 0) 100%)", borderBottomLeftRadius: 40, borderBottomRightRadius: 40, borderTopLeftRadius: 40, borderTopRightRadius: 40}} variants={{FfuxrBpMv: {background: "radial-gradient(7.000000000000001% 45% at 92.4% 50%, var(--token-39139dc2-5826-48d3-90f9-4b466d460b8b, rgb(173, 208, 21)) /* {\"name\":\"Yellow 600\"} */ 0%, rgba(255, 255, 255, 0) 100%)"}, u4WNz6cpY: {background: "radial-gradient(7.000000000000001% 45% at 7.5% 50%, var(--token-39139dc2-5826-48d3-90f9-4b466d460b8b, rgb(173, 208, 21)) /* {\"name\":\"Yellow 600\"} */ 0%, rgba(255, 255, 255, 0) 100%)"}, YG_5kns_4: {background: "radial-gradient(10% 10% at 81.6% 0%, var(--token-39139dc2-5826-48d3-90f9-4b466d460b8b, rgb(173, 208, 21)) /* {\"name\":\"Yellow 600\"} */ 0%, rgba(255, 255, 255, 0) 100%)"}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-g2vNz.framer-rfph60, .framer-g2vNz .framer-rfph60 { display: block; }", ".framer-g2vNz.framer-641rrv { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 597px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 1018px; will-change: var(--framer-will-change-override, transform); }", ".framer-g2vNz .framer-1fzct5f { bottom: -1px; flex: none; left: -1px; overflow: visible; position: absolute; right: -1px; top: -1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-g2vNz.framer-641rrv { gap: 0px; } .framer-g2vNz.framer-641rrv > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-g2vNz.framer-641rrv > :first-child { margin-left: 0px; } .framer-g2vNz.framer-641rrv > :last-child { margin-right: 0px; } }", ".framer-g2vNz.framer-v-au8yqj .framer-1fzct5f { bottom: unset; height: 599px; left: unset; order: 0; position: relative; right: unset; top: unset; width: 1020px; }", ".framer-g2vNz.framer-v-orjkhc .framer-1fzct5f { order: 0; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 597
 * @framerIntrinsicWidth 1018
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"YG_5kns_4":{"layout":["fixed","fixed"]},"FfuxrBpMv":{"layout":["fixed","fixed"]},"u4WNz6cpY":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerXjQriQK0r: React.ComponentType<Props> = withCSS(Component, css, "framer-g2vNz") as typeof Component;
export default FramerXjQriQK0r;

FramerXjQriQK0r.displayName = "Glow Stroke/Huge";

FramerXjQriQK0r.defaultProps = {height: 597, width: 1018};

addPropertyControls(FramerXjQriQK0r, {variant: {options: ["MGVSxjS4S", "YG_5kns_4", "FfuxrBpMv", "u4WNz6cpY"], optionTitles: ["Card Gradient Left", "Card Gradient Right", "Card Gradient Bot-right", "Card Gradient Bot-left"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerXjQriQK0r, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})